* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 62.5%;
}

:root {
  --red: #ed2938;
  --orange: #ff8c01;
  --black: #000000;
  --white: #ffffff;
  --lime: #a2cd72;
}

.img-bg {
  position: absolute;
  min-width: 100vw;
  top: 0;
  left: 0;
  z-index: -11;
  background-color: var(--black) !important;
}

.edit-profile {
  position: relative;
  top:12rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  column-gap: 8rem;
  background-color: var(--black);
  min-height: 82rem;
}

.edit-profile .Mobile {
  display: none;
}

/* picture */
.pic-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.pic-edit img {
  border-radius: 10rem;
  width: 12rem;
  height: 12rem;
}

.pic-edit button {
  font-size: 1.6rem;
  background-color: var(--white);
  border-radius: 2rem;
  padding: 0.8rem;
}

/* edit user profile form */
.user-detail {
  display: grid;
  column-gap: 6.4rem;
  row-gap: 1.6rem;
}

.user-detail .username {
  font-size: 4rem;
  color: var(--orange);
  font-weight: 700;
  grid-column: 1 / 3;
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-group .icon {
  top: 3.6rem;
  left: 0.6rem;
  font-size: 1.6rem;
}

.form-group input,
.form-group select {
  font-size: 2rem;
  height: 5.5rem;
  width: 40rem;
  background-color: rgba(255, 255, 255, 0.15);
  padding-left: 3.2rem;
  margin-bottom: 5rem;
  border: 0;
  border-bottom: #ffffff solid 0.2rem;
  color: #ffffff;
  caret-color: #ffffff;
}

.form-group input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}


.form-group input[type="date"] {
  color-scheme: dark;
  padding-right: 1.6rem;
}

.form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 2rem center;
  background-size: 1em;
}

.form-group option {
  color: var(--black);
}

.form-group label {
  font-size: 2.2rem;
  color: var(--orange);
  margin-bottom: 0.8rem;
}

.user-detail.form-group label {
  font-size: 2.2rem;
  color: var(--orange);
}

.form-group .icon {
  position: absolute;
  top: 5.6rem;
  left: 0.6rem;
  font-size: 2rem;
}

.btn-sub {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.btn-sub .back-btn,
.btn-sub .save-btn {
  font-size: 4.8rem;
  width: 16rem;
  border-radius: 1rem;
}

.btn-sub .back-btn {
  background-color: var(--red);
  color: var(--white);
  transition: background-color 1.5s, color 1.5s;
}

.btn-sub .back-btn:hover{
  background-color: var(--white);
  color: var(--red);
}

.btn-sub .save-btn {
  background-color: var(--orange);
  color: black;
  transition: background-color 1.5s, color 1.5s;
}

.btn-sub .save-btn:hover{
  background-color: var(--white);
  color: var(--orange);

}

/* Responsive Zone */
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .edit-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 100rem;
    top: 4rem;
  }

  .edit-profile .Mobile {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--orange);
    font-size: 3.6rem;
    font-weight: 700;
    color: var(--black);
    margin-bottom: 1rem;
  }

  .pic-edit img {
    width: 10rem;
    height: 10rem;
  }

  .pic-edit button {
    font-size: 1.2rem;
    font-weight: 700;
    background-color: var(--white);
    border-radius: 2rem;
    padding: 0.8rem;
  }

  .user-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 6.4 rem;
    row-gap: 1.6rem;
  }

  .user-detail .username {
    font-size: 2.8rem;
    margin-top: 2rem;
  }

  .form-group label {
    font-size: 1.6rem;
  }

  .form-group .icon {
    top: 3.6rem;
    left: 0.6rem;
    font-size: 1.6rem;
  }

  .form-group input,
  .form-group select {
    font-size: 1.4rem;
    height: 3rem;
    width: 80vw;
    padding-left: 3.2rem;
    margin-bottom: 0.2rem;
  }

  .btn-sub {
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    width: 100vw;
  }
  
  .btn-sub .back-btn,
  .btn-sub .save-btn {
    font-size: 3.2rem;
    width: 10rem;
  }
}
