.card_data {
    background-color: #ff8c01;
    height: 44rem;
    padding: 1.6rem 2.4rem;
    border-radius: 3.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 3.2rem;
    min-height: 32rem;
    width: 32rem;
    transition: height 1.5s, width 1.5s;
}

.card_data:hover{
    width: 36rem;
    height: 48rem;
    
}

.card_data strong{ 
    font-size: 1.6rem;
}

.card_data span{ 
    font-size: 1.6rem;
}

.card_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card_head h1{
    text-decoration: underline;
}

.card_head_icon {
    display: flex;
    justify-content: space-between;
    gap: 0.4rem;
}

strong {
    font-weight: 700;
}

h1 {
    color: #000000;
    margin-top: 0.8rem;
    font-size: 2.4rem;
}


.edit, .bin-mobile {
    font-size: 2.4rem;
    color: #000000;   
    cursor: pointer;
    float: right;
    margin-top: 0.3rem;
    
}
.edit{
    width: 2.4rem;
}

.bin {
    display: none;
}

.bin-mobile{
    display: block;
    width: 2.8rem;
} 

.wrong, .check {
    font-size: 3rem;
    cursor: pointer;
    border-radius: 5rem;
    width: 3.2rem;
}

.wrong {
    color: #ed2938;
    background-color: #fff;
    border: 1px solid #ed2938;
}

.check {
    color: #54B435;
    background-color: #fff;
    border: 1px solid #54B435;
}

.card_status_btn {
    display: flex;
    justify-content: space-evenly;
    padding-top: 1.6rem;
}

input {
    background-color: transparent;
    border: none;
    padding-left: 0.8rem;
}

input::placeholder {
    color: #fff;
}

label {
    color: #fff;
    font-weight: bold;
    float: left;
}

label:after { content: " : " }

@media screen and (min-width: 320px) and (max-width: 819px) {

    .card_data {
        background-color: #ff8c01;
        padding: 1.6rem 1.6rem;
        border-radius: 2.4rem;
        height: 36rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        font-size: 1.6rem;
        line-height: 3rem;
    }

    .card_head_icon {
        display: flex;
        justify-content: space-between;
        gap: 0.8rem;
    }

    h1 {
        font-size: 2.4rem;
    }

    label {
        float: left;
    }

    label:after { content: " : " }

    .edit, .bin-mobile {
        font-size: 2.4rem;
        color: #000000;   
        cursor: pointer;
        float: right;
        margin-top: 0.3rem;
    }

    .bin {
       display: none;
    }
    
}
