.dashboard-container {
  position: relative;
  top: 12vh;
  width: 88vw;
  min-height: 120rem;
  margin: 0 auto 4rem;
  background-color: #000;
  font-family: "Poppins", sans-serif;
}

.dashboard-container-error {
  position: relative;
  top: 12vh;
  width: 88vw;
  min-height: 80rem;
  margin: 0 auto 4rem;
  background-color: #000;
  font-family: "Poppins", sans-serif;
}

.loading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 2vh;
  max-width: 90vw;
  margin: 0 auto;
  text-align: center;
  color: white;
  line-height: 12rem;
}

.loading h1 {
  font-size: 6.4rem;
  color: white;
}

.loading h2 {
  font-size: 5.6rem;
  color: white;
}

.loading button {
  margin-top: 2rem;
  border-radius: 4rem;
  width: 16rem;
  height: 8rem;
  font-size: 2.4rem;
  font-weight: 700;
  background-color: #98ff98;
  transition: width 2s, height 2s;
}

.loading button:hover {
  width: 24rem;
  height: 12rem;
}

/* ========= Profile =========*/
.profile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12.8rem;
  margin-bottom: 8rem;
}

.profile-sec-img,
.profile-sec-info {
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}

.profile-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
}

.profile-img img {
  border-radius: 10rem;
  width: 12rem;
  height: 12rem;
}

.btnProfile {
  font-size: 1.6rem;
  padding: 0.4rem;
  font-weight: bold;
  width: 6vw;
  border-radius: 1.2rem;
}

.height-weight {
  display: flex;
  flex-direction: row;
  column-gap: 2.4rem;
}

.name,
.height,
.weight,
.goal {
  color: #25eae7;
  font-size: 2.4rem;
}

.gender-age,
.height-num,
.weight-num,
.goal-num {
  color: #fff;
  font-size: 2rem;
}

/* ========= Box =========*/
.box {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
}

.box_inside {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background-color: #272626;
  width: 32rem;
  height: 36rem;
  border-radius: 2rem;
  transition: width 1.5s, height 1.5s;
}

.box_inside:hover {
  width: 36rem;
  height: 40rem;
}

.box_inside .frame {
  color: #fff;
}

.box_inside h1 {
  font-size: 6.4rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.box_inside p {
  font-size: 2.4rem;
  text-align: center;
  font-weight: bold;
  margin-top: 4rem;
}

.completionmobile {
  display: none;
}

.dayspentmobile {
  display: none;
}

/* ========= sport progress ========= */
.sportType {
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.sportbar {
  height: 16rem;
  width: 88vw;
  margin-bottom: 4rem;
}

.bar img {
  max-width: 100%;
}

.bar h2 {
  font-size: 2.4rem;
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 1.6rem;
  color: #fff;
}

.sportColor {
  display: flex;
  justify-content: space-around;
  padding: 30px;
}

.sportmobile {
  display: none;
}

.swimming,
.walking,
.hiking,
.bicycling,
.running {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
}

.sportColor-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

/* ========= button ========= */

.btn-act {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-act button {
  text-align: center;
  background-color: #fff;
  border-radius: 4rem;
  font-size: 1.6rem;
  padding: 1.2rem;
  font-weight: bold;
  margin-top: 5rem;
  transition: background-color 1.5s;
}

.btn-act button:hover {
  background-color: orange;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  .profile {
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    align-items: center;
    justify-content: center;
  }

  .loading h1 {
    font-size: 2.4rem;
    color: white;
  }

  .loading h2 {
    font-size: 2rem;
    color: white;
  }

  .loading button {
    margin-top: 2rem;
    border-radius: 4rem;
    width: 8rem;
    height: 4rem;
    font-size: 1.2rem;
    font-weight: 700;
    background-color: #98ff98;
    transition: width 2s, height 2s;
  }

  .loading button:hover {
    width: 12rem;
    height: 6rem;
  }

  .profile-sec-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    column-gap: 2rem;
  }

  .profile-sec-info p {
    font-size: 1.6rem;
  }

  .goal-sec {
    display: grid;
    grid-auto-flow: column;
    grid-column: 1/3;
    justify-content: center;
    align-items: center;
    column-gap: 3.2rem;
  }

  .btnProfile {
    font-size: 1.6rem;
    width: 40vw;
    padding: 1.2rem;
    font-weight: bold;
    border-radius: 10px;
  }

  /* ========= box section ========= */

  .box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    align-items: center;
    justify-items: center;
  }

  .box_inside {
    width: 44vw;
    height: 30vh;
  }

  .box_inside:hover {
    width: 46vw;
    height: 32vh;
  }

  .box_down {
    grid-column: 1/3;
    width: 98%;
  }

  .box_inside h1 {
    font-size: 4.8rem;
  }

  .box_inside p {
    font-size: 1.6rem;
  }

  .completionmobile {
    display: inline-block;
    justify-items: center;
  }

  .completion {
    display: none;
  }

  .dayspentmobile {
    display: inline-block;
    justify-items: center;
  }

  .dayspent {
    display: none;
  }

  .sportbar {
    display: none;
  }

  .sportColor {
    display: none;
  }

  .bar h2 {
    font-size: 1.3rem;
    text-align: left;
    color: #fff;
    padding-left: 2rem;
  }

  .sportmobile {
    display: block;
    width: 110%;
  }

  .btn-act button {
    margin-bottom: 20rem;
  }
}
