
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  height:100%;
}

body {
  background-color: #000;
}

.App {  
  min-height:100%;
}

::-webkit-scrollbar {
  display: none;
}


button {
  cursor: pointer;
}
.swal2-icon {
  zoom : 1.5;
}

.swal2-x-mark {
  zoom : 2.6;
}

.swal2-success-line-tip{
  zoom : 1.6; 
}

.swal2-success-line-long{
  zoom : 1.6; 
}

.swal2-container {
  zoom: 2.5;
}

.swal2-input{
  width: 16rem !important;
}

.swal-add{
  zoom: .5;
  font-size: 1.6rem !important;
  min-width: 28rem !important;
  max-width: 32rem !important ;

}

.swal2-input option{
  font-size: 1.2rem;
}

.add-modal{
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}

.modal-input-group {
  display: flex;
  align-items: center;
}

.add-modal label{
  display: flex;
  text-align: left;
  color: #000;
  font-size: .8rem;
  width: 8rem;
}

@media screen and (max-width: 480px) {
  .add-modal label{
    font-size: .6rem;
  }

  .swal-add{
    zoom: .25;
    font-size: 1.6rem !important;
    min-width: 24rem !important ;
    max-width: 40rem !important ;
  }
}

@media screen and (min-width:481px) and (max-width: 700px) {
  .add-modal label{
    font-size: .6rem;
  }

  .swal-add{
    font-size: 1.2rem !important;
    min-width: 16rem !important ;
    max-width: 20rem !important ;
  }
}