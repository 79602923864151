.navbar {
  opacity: 0.8;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
  width: 100%;
  height: 6rem;
  position: fixed;
  font-family: "Roboto", sans-serif;
  z-index: 10000;
}

.bg-dark{
  background-color: rgba(64,64,64, 0.5);
}

.welcome {
  display: flex;
  flex-direction: column;
  
  padding-left: 1rem;
  font-family: 'Inter Tight', sans-serif;
}

.welcome a{
  display: flex;
  column-gap: 1rem;
}
.welcome img {
  width: 4rem;
}

.welcome h1 {
  color: #ff8c01;
  font-size: 2.4rem;
  font-weight: 700;
  font-family: 'Inter Tight', sans-serif;
}
.welcome span {
  font-size: 2.4rem;
}

.btnNav {
  font-family: 'Poppins', sans-serif;
  padding: .6rem 1.2rem;
  margin: 0.2rem 1.2rem;
  border-radius: 2rem !important;
  font-size: 1.6rem !important;
  font-weight: 700;
  background-color:aqua;
  color: black;
  transition: background-color 1.5s,color 1.5s,border-color 1.5s;
}

.btnNav:hover{
  background-color:#ff8c01;
  color: white;
  border-color: white;
}

.menu-icon {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 479px) {
  /* ทำ hamburger menu */
  .navbar {
    opacity: 0.8;
    display: flex;
    align-content: space-between;
    padding: 1rem 1rem;
    width: 100vw;
    position: fixed;
  }

  .btn-mobile {
    position: fixed;
    opacity: 0.8;
    background-color: rgba(64,64,64, 0.5);
    top:6rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    width:100vw;
    font-size: 16px;
    padding: 0;
    z-index: 10;
  }

  .btnNav {
    margin:.8rem .8rem;
  }

  .menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1rem;
  }
}
